import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../../services/window/window.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public navIsFixed: boolean;
  public mobileOpen: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window
  ) { }

  ngOnInit() {
    this.navIsFixed = false;
    this.mobileOpen = false;
  }

  @HostListener('window:scroll', []) onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number > window.innerHeight) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && number < 10) {
      this.navIsFixed = false;
    }
  }

  doScroll(id: string) {
    id = id.split('#')[1];
    const el = this.document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }

  toogleMenu() {
    this.mobileOpen = !this.mobileOpen;

    if (this.mobileOpen) {
      this.document.body.className = 'noScroll';
    } else {
      this.document.body.className = '';
    }
  }
}
