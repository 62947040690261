import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toId'
})
export class ToIdPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    const mapAccentHex = {
      'a' : /[\xE0-\xE6]/g,
      'e' : /[\xE8-\xEB]/g,
      'i' : /[\xEC-\xEF]/g,
      'o' : /[\xF2-\xF6]/g,
      'u' : /[\xF9-\xFC]/g,
      'c' : /\xE7/g,
      'n' : /\xF1/g,
      '-' : /\s/g
    };

    value = value.toLocaleLowerCase();

    for (const char of Object.keys(mapAccentHex)) {
      const regularExpress = mapAccentHex[char];
      value = value.replace( regularExpress, char );
    }

    return value;
  }

}
