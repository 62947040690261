import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './pages/auth/auth.component';
import { LandingComponent } from './pages/landing/landing.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { StudentiesComponent } from './pages/studenties/studenties.component';
import { ContentComponent } from './pages/content/content.component';
import { UsersComponent } from './pages/users/users.component';

const appRoutes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'studenties', component: StudentiesComponent },
  { path: 'content', component: ContentComponent },
  { path: 'users', component: UsersComponent },
  // otherwise redirect to login
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
