import { Component, OnInit, Input } from '@angular/core';
import { text } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-box-information',
  templateUrl: './box-information.component.html',
  styleUrls: ['./box-information.component.scss']
})
export class BoxInformationComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() type: string;

  visible: boolean;

  constructor() { }

  ngOnInit() {
    this.visible = true;
  }

  close() {
    this.visible = false;
    return false;
  }
}
