import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthComponent } from './pages/auth/auth.component';
import { LandingComponent } from './pages/landing/landing.component';

import { WINDOW_PROVIDERS } from './services/window/window.service';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { SlidePeoplesComponent } from './components/slide-peoples/slide-peoples.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { ToIdPipe } from './pipes/to-id.pipe';
import { InternalHeaderComponent } from './components/internal-header/internal-header.component';
import { InternalFooterComponent } from './components/internal-footer/internal-footer.component';
import { SmallBoxComponent } from './components/small-box/small-box.component';
import { BoxInformationComponent } from './components/box-information/box-information.component';
import { StudentiesComponent } from './pages/studenties/studenties.component';
import { ContentComponent } from './pages/content/content.component';
import { UsersComponent } from './pages/users/users.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AuthComponent,
    LandingComponent,
    ContactFormComponent,
    SlidePeoplesComponent,
    DashboardComponent,
    ToIdPipe,
    InternalHeaderComponent,
    InternalFooterComponent,
    SmallBoxComponent,
    BoxInformationComponent,
    StudentiesComponent,
    ContentComponent,
    UsersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features,
    AngularFireDatabaseModule
  ],
  providers: [WINDOW_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
