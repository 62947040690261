// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  analyticsID: 'UA-118325163-1',
  firebase: {
    apiKey: 'AIzaSyBqVeHhI4d9BLddBczDGHRHFacNqjqOVeo',
    authDomain: 'warmupenglish-web.firebaseapp.com',
    databaseURL: 'https://warmupenglish-web.firebaseio.com',
    projectId: 'warmupenglish-web',
    storageBucket: 'warmupenglish-web.appspot.com',
    messagingSenderId: '429720589524'
  }
};
