import { SlideItem } from './slide-item.model';
import { Observable } from 'rxjs';

export class Slide {
  public id: string;
  public items: Observable<SlideItem[]>;

  constructor(id: string) {
    this.id = id;
  }
}
