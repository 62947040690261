import { Component, OnInit, OnDestroy } from '@angular/core';
import { SlideItem } from '../../models/slide-item.model';
import { Slide } from '../../models/slide.model';
import { Page } from '../../models/page.model';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  private items: Observable<SlideItem[]>;
  public slide = new Slide('testmonials');

  public pages: Observable<Page[]>;

  constructor(db: AngularFireDatabase) {
    this.items = db.list<SlideItem>('testmonials').valueChanges();
    this.pages = db.list<Page>('pages').valueChanges();
  }

  ngOnInit() {
    this.slide.items = this.items;
  }
}
