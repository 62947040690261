import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isAutenticated: boolean;

  ngOnInit() {
    if (location.pathname.length > 1 && location.pathname.indexOf('auth') !== 1) {
      this.isAutenticated = true;
    } else {
      this.isAutenticated = false;
    }
  }


}
