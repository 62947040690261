import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SlideItem } from '../../models/slide-item.model';
import { Slide } from '../../models/slide.model';

@Component({
  selector: 'app-slide-peoples',
  templateUrl: './slide-peoples.component.html',
  styleUrls: ['./slide-peoples.component.scss']
})
export class SlidePeoplesComponent implements OnInit {
  @Input() content: Slide;
  @Input() items: Observable<SlideItem[]>;

  left: string;

  constructor() {}

  ngOnInit() {
    // this.content.items.forEach(a => { console.log('a', a); });
  }

  select(index: number) {
    this.left = `-${index * 100}vw`;

    return false;
  }

}
